<template>
	<v-timeline-item small>
		<v-card>
			<v-card-subtitle class="py-2">
				{{ $store.getters.getDecodedJwtEmpresa.nombre }}
				<v-icon small color="primary">mdi-account</v-icon>
				- {{ new Date().toLocaleString() }}
			</v-card-subtitle>
			<div class="px-4 py-1">
				<text-editor
					v-model="message.msg"
					class="pb-2"
					filled
					dense
					hide-details="auto"
					label="Tu mensaje aquí"
				></text-editor>
			</div>

			<template v-if="$root.acceso('CONTROL_IMPAGADOS')">
				<v-subheader>Método de contacto</v-subheader>
				<div class="d-flex px-4">
					<v-select
						v-model="message.tipo"
						dense
						hide-details
						filled
						clearable
						placeholder="Ninguno"
						:items="['Llamada', 'Email', 'Comercial', 'Otros']"
					/>
				</div>
			</template>

			<!-- <v-radio-group class="px-4 mt-0 mb-0" v-model="message.tipo" row hide-details="auto">
				<v-radio dense hide-details="auto" label="Nada" :value="null"></v-radio>
				<v-radio dense hide-details="auto" label="Llamada" value="llamada"></v-radio>
				<v-radio dense hide-details="auto" label="Email" value="email"></v-radio>
			</v-radio-group>-->

			<div class="pa-4">
				<v-subheader>Adjuntar archivos</v-subheader>
				<DragAndDrop v-model="message.files" />
				<v-chip class="mt-2" small label>{{ message.files.length }} adjuntos</v-chip>
			</div>
		</v-card>
	</v-timeline-item>
</template>

<script>
export default {
	props: {
		value: Object
	},
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
		DragAndDrop: () => import("@/components/DragAndDrop.vue"),
	},
	computed: {
		message: {
			get() { return this.value },
			set(v) { this.$emit('input', v) }
		}
	}
}
</script>